.sign-in {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-size: cover;
  text-align: center;
  // color: white;

  & .content {
    background-image: url("../public/logo.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 50%;
    position: absolute;
    width: 360px;
    height: 72px;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -180px;

    & h2 {
      font-size: 40px;
      font-weight: 700;
    }

    & span {
      position: relative;
      font-size: 16px;
      font-weight: 400;
      margin-top: 50px;
    }
  }
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
@keyframes awsui_spinner-rotator_1612d_oaaqr_1 {
  0% {
    transform: rotate(var(--awsui-spinner-rotator-from-g964ok));
  }
  100% {
    transform: rotate(var(--awsui-spinner-rotator-to-g964ok));
  }
}
@keyframes awsui_spinner-line-left_1612d_oaaqr_1 {
  0% {
    transform: rotate(var(--awsui-spinner-line-left-from-g964ok));
  }
  50% {
    transform: rotate(var(--awsui-spinner-line-left-to-g964ok));
  }
  100% {
    transform: rotate(var(--awsui-spinner-line-left-from-g964ok));
  }
}
@keyframes awsui_spinner-line-right_1612d_oaaqr_1 {
  0% {
    transform: rotate(var(--awsui-spinner-line-right-from-g964ok));
  }
  50% {
    transform: rotate(var(--awsui-spinner-line-right-to-g964ok));
  }
  100% {
    transform: rotate(var(--awsui-spinner-line-right-from-g964ok));
  }
}
.awsui_root_1612d_oaaqr_183:not(#\9) {
  --awsui-spinner-rotator-from-g964ok: 0deg;
  --awsui-spinner-rotator-to-g964ok: 360deg;
  --awsui-spinner-line-left-from-g964ok: 0deg;
  --awsui-spinner-line-left-to-g964ok: 120deg;
  --awsui-spinner-line-right-from-g964ok: 90deg;
  --awsui-spinner-line-right-to-g964ok: -30deg;
  display: inline-block;
  vertical-align: top;
  /*stylelint-disable-next-line @cloudscape-design/no-motion-outside-of-mixin */
  animation: awsui_spinner-rotator_1612d_oaaqr_1 0.7s linear infinite;
  box-sizing: border-box;
  line-height: 0;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_root_1612d_oaaqr_183.awsui_size-normal_1612d_oaaqr_198:not(#\9) {
  inline-size: var(--size-icon-normal-0m1722, 16px);
  block-size: var(--size-icon-normal-0m1722, 16px);
  padding-block: calc((var(--size-icon-normal-0m1722, 16px) - 12px) / 2);
  padding-inline: calc((var(--size-icon-normal-0m1722, 16px) - 12px) / 2);
  margin-block: calc((var(--line-height-body-m-30ar75, 20px) - var(--size-icon-normal-0m1722, 16px)) / 2);
  box-sizing: border-box;
}
.awsui_root_1612d_oaaqr_183.awsui_size-big_1612d_oaaqr_206:not(#\9) {
  inline-size: var(--size-icon-big-zkdryd, 32px);
  block-size: var(--size-icon-big-zkdryd, 32px);
  padding-block: calc((var(--size-icon-big-zkdryd, 32px) - 24px) / 2);
  padding-inline: calc((var(--size-icon-big-zkdryd, 32px) - 24px) / 2);
  margin-block: calc((var(--line-height-heading-xl-avbttk, 30px) - var(--size-icon-big-zkdryd, 32px)) / 2);
  box-sizing: border-box;
}
.awsui_root_1612d_oaaqr_183.awsui_size-large_1612d_oaaqr_214:not(#\9) {
  inline-size: var(--size-icon-large-bivhmh, 48px);
  block-size: var(--size-icon-large-bivhmh, 48px);
  padding-block: calc((var(--size-icon-large-bivhmh, 48px) - 36px) / 2);
  padding-inline: calc((var(--size-icon-large-bivhmh, 48px) - 36px) / 2);
  margin-block: calc((var(--line-height-display-l-8nioft, 48px) - var(--size-icon-large-bivhmh, 48px)) / 2);
  box-sizing: border-box;
}
.awsui_root_1612d_oaaqr_183.awsui_variant-normal_1612d_oaaqr_222:not(#\9) {
  color: currentColor;
}
.awsui_root_1612d_oaaqr_183.awsui_variant-disabled_1612d_oaaqr_225:not(#\9) {
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
}
.awsui_root_1612d_oaaqr_183.awsui_variant-inverted_1612d_oaaqr_228:not(#\9) {
  color: var(--color-text-inverted-pvc88w, #ffffff);
}
.awsui_root_1612d_oaaqr_183:not(#\9):dir(rtl) {
  --awsui-spinner-rotator-from-g964ok: 360deg;
  --awsui-spinner-rotator-to-g964ok: 0deg;
  --awsui-spinner-line-left-from-g964ok: 0deg;
  --awsui-spinner-line-left-to-g964ok: -120deg;
  --awsui-spinner-line-right-from-g964ok: -90deg;
  --awsui-spinner-line-right-to-g964ok: 30deg;
}

.awsui_circle_1612d_oaaqr_240:not(#\9) {
  display: inline-block;
  inline-size: 50%;
  block-size: 100%;
  overflow: hidden;
  position: relative;
}
.awsui_circle_1612d_oaaqr_240:not(#\9):after {
  position: absolute;
  box-sizing: border-box;
  content: "";
  border-start-start-radius: 50%;
  border-start-end-radius: 50%;
  border-end-start-radius: 50%;
  border-end-end-radius: 50%;
  border-block: 2px solid;
  border-inline: 2px solid;
  border-inline-end-color: transparent;
  border-block-end-color: transparent;
}
.awsui_circle_1612d_oaaqr_240:not(#\9):after {
  /*stylelint-disable-next-line @cloudscape-design/no-motion-outside-of-mixin */
  animation: 1.5s ease-in-out infinite;
  inset-block-start: 0;
  inset-inline-start: 0;
  block-size: 100%;
  inline-size: 200%;
}
.awsui_circle_1612d_oaaqr_240.awsui_circle-left_1612d_oaaqr_268:not(#\9):after {
  inset-inline-start: 0;
  /*stylelint-disable-next-line @cloudscape-design/no-motion-outside-of-mixin */
  animation-name: awsui_spinner-line-left_1612d_oaaqr_1;
}
.awsui_circle_1612d_oaaqr_240.awsui_circle-right_1612d_oaaqr_273:not(#\9):after {
  inset-inline-start: -100%;
  /*stylelint-disable-next-line @cloudscape-design/no-motion-outside-of-mixin */
  animation-name: awsui_spinner-line-right_1612d_oaaqr_1;
}
.chat {
  .typing {
    // animation: slideIn 0.3s ease-in-out;
    padding-top: 15px;
    margin-left: 50px;
  }

  .typing__dot {
    float: left;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: #8d8c91;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
  }

  .typing__dot:nth-child(1) {
    animation-delay: 0s;
  }

  .typing__dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .typing__dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes loadingFade {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.8;
    }

    100% {
      opacity: 0;
    }
  }

  .spacer {
    flex-grow: 1;
  }

  .input {
    margin-top: 20px;
    display: flex;
    border-radius: 12px;
    border: 2px solid #7d8998;
    padding: 4px 8px;
    vertical-align: top;
    font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
    font-size: 14px;

    textarea {
      resize: none;
      flex: 1;
      display: block;
      line-height: 22px;
      padding: 4px 12px;
      border: none;
      border-radius: 8px;
      // background-color: aqua;
      outline-width: 0;
    }

    button {
      border: none;
      background-color: transparent;
      color: #0972d3;
      margin-top: 1px;
    }

    ::-webkit-input-placeholder {
      font-style: italic;
    }

    :-moz-placeholder {
      font-style: italic;
    }

    ::-moz-placeholder {
      font-style: italic;
    }

    :-ms-input-placeholder {
      font-style: italic;
    }
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  .responses {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    color: #7d8998;

    button {
      border: none;
      background-color: transparent;
      color: #0972d3;
      position: relative;
      padding: 0 10px;
      font-size: 14px;

      &::before {
        content: "\"";
      }

      &::after {
        content: "\"";
      }

      &:not(:last-child) {
       padding-right: 0px;
      }
    }
  }

  // Hide the repeatitive percentage next to the progress bar
  progress + span {
    display: none;
  }
}

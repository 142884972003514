@import "./common/constants.scss";

body {
  margin: 0;
  padding: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#firebaseui-auth-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -36px;
  margin-left: -118px;
}


.global-spinner {
  position: static !important;
  right: 20px;
  bottom: 20px;
}

img.profile {
  border: 1px solid #ccc;
  width: 96px;
  height: 96px;
}

.rmwc-avatar {
  border: 2px solid #ccc;
  margin-bottom: $spacing-small;
}

.awsui-context-top-navigation {
  & span[class^="awsui_title"] {
    &:after {
      content: "by Asemio";
      font-size: 14px;
      font-weight: 400;
      margin-left: 6px;
    }
  }
}

a[class^="awsui_header-link"] {
  display: block !important;
}

span[class^="awsui_header-link-text"] {
  display: block;
  margin-top: 16px;
  font-weight: 700 !important;
}

.awsui_overlap_hyvsj_1u1kg_246 {
  height: 58px; // Chipi Why?, why?, why?
}

.messages {
    .in {
    span.rmwc-icon {
      float: left;
    }

    div.message {
      margin-left: 50px;
      margin-right: 25%;
      color: white;
      // animation: slideIn 0.3s ease-in-out;
      background-color: #0972d3;
      padding: 8px 12px 10px 12px;
      border-radius: 12px;
      border-bottom-left-radius: 0;
    }

    div.time {
      margin-left: 50px;
      color: #7d8998;
      font-size: 12px;
      margin-top: 8px;
      // margin-bottom: 4px;
      // float: right;
    }
  }

  .out {

    // animation: slideIn 0.3s ease-in-out;
    span.rmwc-icon {
      float: right;
    }

    div.message {
      margin-right: 50px;
      margin-left: 25%;

      span {
        color: white;
        padding: 8px 12px;
        background-color: #7d8998;
        padding: 8px 12px 10px 12px;
        border-radius: 12px;
        border-bottom-right-radius: 0;
        float: right;
      }
    }

    div.time {
      margin-right: 50px;
      color: #7d8998;
      font-size: 12px;
      // margin-top: 4px;
      // margin-bottom: 4px;
      float: right;
      clear: both;
    }
  }
}

button.show-more {
  color: #0972d3;
  text-decoration: none;
  border: none;
  padding: none;
  background-color: transparent;
  padding: 0;
}

td[class^="awsui_body-cell"] {
  vertical-align: top;
}

td[class^="awsui_selection-control"] {
  & label {
    height: auto !important;
    top: 8px !important;
  }
}

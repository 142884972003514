/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_button-dropdown_sne0l_ulg38_145:not(#\9) {
  display: inline-block;
}
.awsui_button-dropdown_sne0l_ulg38_145.awsui_full-width_sne0l_ulg38_148:not(#\9) {
  inline-size: 100%;
  display: block;
}

.awsui_items-list-container_sne0l_ulg38_153:not(#\9) {
  padding-block: 0;
  padding-inline: 0;
  margin-block: 0;
  margin-inline: 0;
  animation: awsui_awsui-motion-fade-in-0_sne0l_ulg38_1 500ms var(--motion-easing-show-quick-vc5zgd, ease-out);
  animation-fill-mode: none;
}
@media (prefers-reduced-motion: reduce) {
  .awsui_items-list-container_sne0l_ulg38_153:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_items-list-container_sne0l_ulg38_153:not(#\9), .awsui-mode-entering .awsui_items-list-container_sne0l_ulg38_153:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_rotate_sne0l_ulg38_172:not(#\9) {
  transform: rotate(0deg);
  transition: transform var(--motion-duration-rotate-180-dpvl4m, 135ms) var(--motion-easing-rotate-180-e270ko, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_rotate_sne0l_ulg38_172:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_rotate_sne0l_ulg38_172:not(#\9), .awsui-mode-entering .awsui_rotate_sne0l_ulg38_172:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_rotate-open_sne0l_ulg38_186:not(#\9) {
  transform: rotate(-180deg);
}

.awsui_header_sne0l_ulg38_190:not(#\9) {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-block: var(--space-s-34lx8l, 12px);
  padding-inline: var(--space-l-t419sm, 20px);
  border-block-start: var(--border-width-field-h1g7tw, 2px) solid transparent;
  border-block-end: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-dropdown-group-2xoluv, #c6c6cd);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid transparent;
}

.awsui_title_sne0l_ulg38_201:not(#\9),
.awsui_description_sne0l_ulg38_202:not(#\9) {
  color: var(--color-text-top-navigation-title-v9cb3m, #0f141a);
}

.awsui_trigger-button_sne0l_ulg38_206.awsui_full-width_sne0l_ulg38_148:not(#\9) {
  display: grid;
  grid-template-columns: 1fr auto;
}
.awsui_trigger-button_sne0l_ulg38_206.awsui_full-width_sne0l_ulg38_148.awsui_loading_sne0l_ulg38_210:not(#\9) {
  grid-template-columns: auto 1fr auto;
}

.awsui_split-trigger-wrapper_sne0l_ulg38_214:not(#\9) {
  display: flex;
}
.awsui_split-trigger-wrapper_sne0l_ulg38_214 > .awsui_trigger-item_sne0l_ulg38_217 > .awsui_trigger-button_sne0l_ulg38_206:not(#\9):focus, .awsui_split-trigger-wrapper_sne0l_ulg38_214 > .awsui_trigger-item_sne0l_ulg38_217 > .awsui_trigger-button_sne0l_ulg38_206:not(#\9):hover {
  z-index: 1;
}
.awsui_split-trigger-wrapper_sne0l_ulg38_214 > .awsui_trigger-item_sne0l_ulg38_217:not(#\9):not(:last-child) > .awsui_trigger-button_sne0l_ulg38_206 {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  padding-inline-end: var(--space-m-udix3p, 16px);
  margin-inline-end: var(--space-xxxs-zbmxqb, 2px);
}
.awsui_split-trigger-wrapper_sne0l_ulg38_214 > .awsui_trigger-item_sne0l_ulg38_217:not(#\9):not(:last-child) > .awsui_trigger-button_sne0l_ulg38_206.awsui_has-no-text_sne0l_ulg38_226 {
  padding-inline: var(--space-button-icon-only-horizontal-zsf2nw, 6px);
}
.awsui_split-trigger-wrapper_sne0l_ulg38_214 > .awsui_trigger-item_sne0l_ulg38_217:not(#\9):not(:last-child) > .awsui_trigger-button_sne0l_ulg38_206.awsui_has-no-text_sne0l_ulg38_226.awsui_visual-refresh_sne0l_ulg38_229 {
  padding-inline-start: calc(var(--space-s-34lx8l, 12px) - 2px);
}
.awsui_split-trigger-wrapper_sne0l_ulg38_214 > .awsui_trigger-item_sne0l_ulg38_217:not(#\9):not(:first-child) > .awsui_trigger-button_sne0l_ulg38_206 {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  padding-inline: calc(var(--space-xs-zb16t3, 8px) - 2px);
}
.awsui_split-trigger-wrapper_sne0l_ulg38_214 > .awsui_trigger-item_sne0l_ulg38_217:not(#\9):not(:first-child).awsui_visual-refresh_sne0l_ulg38_229 > .awsui_trigger-button_sne0l_ulg38_206 {
  padding-inline-end: calc(var(--space-s-34lx8l, 12px) - 2px);
}
.awsui_split-trigger-wrapper_sne0l_ulg38_214 > .awsui_trigger-item_sne0l_ulg38_217.awsui_variant-normal_sne0l_ulg38_240:not(#\9):not(:last-child) > .awsui_trigger-button_sne0l_ulg38_206 {
  margin-inline-end: 0;
}
.awsui_split-trigger-wrapper_sne0l_ulg38_214 > .awsui_trigger-item_sne0l_ulg38_217.awsui_variant-normal_sne0l_ulg38_240:not(#\9):not(:first-child) > .awsui_trigger-button_sne0l_ulg38_206 {
  margin-inline-start: calc(var(--border-width-field-h1g7tw, 2px) * -1);
}

.awsui_split-trigger_sne0l_ulg38_214:not(#\9) {
  display: contents;
}

.awsui_dropdown-trigger_sne0l_ulg38_251:not(#\9) {
  display: contents;
}

.awsui_main-action-full-width_sne0l_ulg38_255:not(#\9) {
  flex: 1 1 0;
}

.awsui_main-action-trigger-full-width_sne0l_ulg38_259:not(#\9) {
  flex: 0 0 auto;
}

.awsui_test-utils-button-trigger_sne0l_ulg38_263:not(#\9) {
  /* used in test-utils */
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_arrow_xjuzf_hg5hv_289:not(#\9) {
  inline-size: 20px;
  block-size: 10px;
}
.awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9), .awsui_arrow-inner_xjuzf_hg5hv_293:not(#\9) {
  position: absolute;
  overflow: hidden;
  inline-size: 20px;
  block-size: 10px;
  inset-block-start: 0;
  inset-inline-start: 0;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after, .awsui_arrow-inner_xjuzf_hg5hv_293:not(#\9)::after {
  content: "";
  box-sizing: border-box;
  display: inline-block;
  position: absolute;
  border-start-start-radius: 2px;
  border-start-end-radius: 0;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inline-size: 14px;
  block-size: 14px;
  transform: rotate(45deg);
  transform-origin: 0 100%;
}
.awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9):dir(rtl)::after, .awsui_arrow-inner_xjuzf_hg5hv_293:not(#\9):dir(rtl)::after {
  transform: rotate(-45deg);
  transform-origin: 100% 100%;
}
.awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after {
  background-color: var(--color-border-popover-v2sc7l, #b4b4bb);
}
.awsui_arrow-inner_xjuzf_hg5hv_293:not(#\9) {
  inset-block-start: calc(var(--border-width-popover-h2a419, 2px) + 1px);
}
.awsui_arrow-inner_xjuzf_hg5hv_293:not(#\9)::after {
  border-start-start-radius: 1px;
  border-start-end-radius: 0;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
  background-color: var(--color-background-popover-eqi5zi, #ffffff);
}
.awsui_arrow-position-right-top_xjuzf_hg5hv_335 > .awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after, .awsui_arrow-position-right-bottom_xjuzf_hg5hv_335 > .awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after {
  box-shadow: -0.71px 0.71px 4px -2px var(--color-shadow-default-u7prpu, rgba(15, 20, 26, 0.12));
}
.awsui_arrow-position-left-top_xjuzf_hg5hv_338 > .awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after, .awsui_arrow-position-left-bottom_xjuzf_hg5hv_338 > .awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after {
  box-shadow: 0.71px -0.71px 4px -2px var(--color-shadow-default-u7prpu, rgba(15, 20, 26, 0.12));
}
.awsui_arrow-position-top-center_xjuzf_hg5hv_341 > .awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after, .awsui_arrow-position-top-right_xjuzf_hg5hv_341 > .awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after, .awsui_arrow-position-top-left_xjuzf_hg5hv_341 > .awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after, .awsui_arrow-position-top-responsive_xjuzf_hg5hv_341 > .awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after {
  box-shadow: -0.71px -0.71px 4px -2px var(--color-shadow-default-u7prpu, rgba(15, 20, 26, 0.12));
}
.awsui_arrow-position-bottom-center_xjuzf_hg5hv_344 > .awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after, .awsui_arrow-position-bottom-right_xjuzf_hg5hv_344 > .awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after, .awsui_arrow-position-bottom-left_xjuzf_hg5hv_344 > .awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after, .awsui_arrow-position-bottom-responsive_xjuzf_hg5hv_344 > .awsui_arrow-outer_xjuzf_hg5hv_293:not(#\9)::after {
  box-shadow: 0.71px 0.71px 4px -2px var(--color-shadow-default-u7prpu, rgba(15, 20, 26, 0.12));
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_body_xjuzf_hg5hv_492:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  word-wrap: break-word;
  max-inline-size: 100%;
  overflow: hidden;
  padding-block: var(--space-s-34lx8l, 12px);
  padding-inline: var(--space-m-udix3p, 16px);
}
.awsui_body-overflow-visible_xjuzf_hg5hv_528:not(#\9) {
  overflow: visible;
}

.awsui_has-dismiss_xjuzf_hg5hv_532:not(#\9) {
  display: flex;
  align-items: baseline;
}

.awsui_dismiss_xjuzf_hg5hv_537:not(#\9) {
  margin-block: calc(-1 * var(--space-xs-zb16t3, 8px));
  margin-inline-start: 0;
  margin-inline-end: calc(-1 * (var(--space-xxs-p8yyaw, 4px) + var(--border-width-popover-h2a419, 2px)));
  flex: 0 0 auto;
  order: 1;
}

.awsui_dismiss-control_xjuzf_hg5hv_545:not(#\9) {
  /* used in test-utils */
}

.awsui_header-row_xjuzf_hg5hv_549:not(#\9) {
  margin-block-end: var(--space-xs-zb16t3, 8px);
}

.awsui_header_xjuzf_hg5hv_549:not(#\9) {
  word-wrap: break-word;
  max-inline-size: 100%;
  overflow: hidden;
  font-size: var(--font-size-heading-xs-wo4hpf, 14px);
  line-height: var(--line-height-heading-xs-aeleja, 18px);
  font-weight: var(--font-weight-heading-xs-l7rqme, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  flex: 1 1 auto;
  /* stylelint-disable-next-line selector-max-type */
}
.awsui_header_xjuzf_hg5hv_549 > h2:not(#\9) {
  font-size: var(--font-size-heading-xs-wo4hpf, 14px);
  line-height: var(--line-height-heading-xs-aeleja, 18px);
  font-weight: var(--font-weight-heading-xs-l7rqme, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  display: inline;
}

.awsui_content_xjuzf_hg5hv_574:not(#\9) {
  word-wrap: break-word;
  max-inline-size: 100%;
  overflow: hidden;
  color: var(--color-text-body-secondary-cwla8d, #424650);
  flex: 1 1 auto;
  min-inline-size: 0;
}
.awsui_content-overflow-visible_xjuzf_hg5hv_582:not(#\9) {
  overflow: visible;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_container_xjuzf_hg5hv_730:not(#\9) {
  display: inline-block;
  position: fixed;
  inset-block-start: -9999px;
  inset-inline-start: -9999px;
  z-index: 2000;
}

.awsui_container-body_xjuzf_hg5hv_738:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  border-start-start-radius: var(--border-radius-popover-clv1ya, 8px);
  border-start-end-radius: var(--border-radius-popover-clv1ya, 8px);
  border-end-start-radius: var(--border-radius-popover-clv1ya, 8px);
  border-end-end-radius: var(--border-radius-popover-clv1ya, 8px);
  background-color: var(--color-background-popover-eqi5zi, #ffffff);
  box-shadow: var(--shadow-popover-8zls8c, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
  border-block: var(--border-width-popover-h2a419, 2px) solid var(--color-border-popover-v2sc7l, #b4b4bb);
  border-inline: var(--border-width-popover-h2a419, 2px) solid var(--color-border-popover-v2sc7l, #b4b4bb);
}

.awsui_container-body-variant-annotation_xjuzf_hg5hv_778:not(#\9) {
  background-color: var(--color-background-status-info-gflgo4, #f0fbff);
  border-color: var(--color-border-status-info-z6pwqy, #006ce0);
}

.awsui_container-body-size-small_xjuzf_hg5hv_783:not(#\9) {
  max-inline-size: 210px;
}
.awsui_container-body-size-small_xjuzf_hg5hv_783.awsui_fixed-width_xjuzf_hg5hv_786:not(#\9) {
  inline-size: 210px;
}

.awsui_container-body-size-medium_xjuzf_hg5hv_790:not(#\9) {
  max-inline-size: 310px;
}
.awsui_container-body-size-medium_xjuzf_hg5hv_790.awsui_fixed-width_xjuzf_hg5hv_786:not(#\9) {
  inline-size: 310px;
}

.awsui_container-body-size-large_xjuzf_hg5hv_797:not(#\9) {
  max-inline-size: 482px;
}
@media (max-width: 482px) {
  .awsui_container-body-size-large_xjuzf_hg5hv_797:not(#\9) {
    max-inline-size: 310px;
  }
}
.awsui_container-body-size-large_xjuzf_hg5hv_797.awsui_fixed-width_xjuzf_hg5hv_786:not(#\9) {
  inline-size: 482px;
}

.awsui_container-arrow_xjuzf_hg5hv_809:not(#\9) {
  position: absolute;
  display: inline-block;
}
.awsui_container-arrow-position-right-top_xjuzf_hg5hv_813:not(#\9), .awsui_container-arrow-position-right-bottom_xjuzf_hg5hv_813:not(#\9) {
  transform: rotate(-90deg);
  transform-origin: 0 100%;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_container-arrow-position-right-top_xjuzf_hg5hv_813:not(#\9):dir(rtl), .awsui_container-arrow-position-right-bottom_xjuzf_hg5hv_813:not(#\9):dir(rtl) {
  transform: rotate(90deg);
  transform-origin: 100% 100%;
}
.awsui_container-arrow-position-right-top_xjuzf_hg5hv_813:not(#\9) {
  inset-block-start: calc(12px + 10px);
  inset-inline-start: 0;
}
.awsui_container-arrow-position-right-bottom_xjuzf_hg5hv_813:not(#\9) {
  inset-block-end: 12px;
  inset-inline-start: 0;
}
.awsui_container-arrow-position-left-top_xjuzf_hg5hv_830:not(#\9), .awsui_container-arrow-position-left-bottom_xjuzf_hg5hv_830:not(#\9) {
  transform: rotate(90deg);
  transform-origin: 100% 100%;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_container-arrow-position-left-top_xjuzf_hg5hv_830:not(#\9):dir(rtl), .awsui_container-arrow-position-left-bottom_xjuzf_hg5hv_830:not(#\9):dir(rtl) {
  transform: rotate(-90deg);
  transform-origin: 0% 100%;
}
.awsui_container-arrow-position-left-top_xjuzf_hg5hv_830:not(#\9) {
  inset-block-start: calc(12px + 10px);
  inset-inline-end: 0;
}
.awsui_container-arrow-position-left-bottom_xjuzf_hg5hv_830:not(#\9) {
  inset-block-end: 12px;
  inset-inline-end: 0;
}
.awsui_container-arrow-position-top-center_xjuzf_hg5hv_847:not(#\9), .awsui_container-arrow-position-top-right_xjuzf_hg5hv_847:not(#\9), .awsui_container-arrow-position-top-left_xjuzf_hg5hv_847:not(#\9), .awsui_container-arrow-position-top-responsive_xjuzf_hg5hv_847:not(#\9) {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}
.awsui_container-arrow-position-top-center_xjuzf_hg5hv_847:not(#\9) {
  inset-block-end: -10px;
  inset-inline-start: calc(50% - 10px);
}
.awsui_container-arrow-position-top-right_xjuzf_hg5hv_847:not(#\9) {
  inset-block-end: -10px;
  inset-inline-start: 12px;
}
.awsui_container-arrow-position-top-left_xjuzf_hg5hv_847:not(#\9) {
  inset-block-end: -10px;
  inset-inline-start: calc(100% - 20px - 12px);
}
.awsui_container-arrow-position-bottom-center_xjuzf_hg5hv_863:not(#\9) {
  inset-block-start: -10px;
  inset-inline-start: calc(50% - 10px);
}
.awsui_container-arrow-position-bottom-right_xjuzf_hg5hv_867:not(#\9) {
  inset-block-start: -10px;
  inset-inline-start: 12px;
}
.awsui_container-arrow-position-bottom-left_xjuzf_hg5hv_871:not(#\9) {
  inset-block-start: -10px;
  inset-inline-start: calc(100% - 20px - 12px);
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_container_xjuzf_hg5hv_730:not(#\9) {
  animation: awsui_awsui-motion-fade-in_xjuzf_hg5hv_1 var(--motion-duration-show-paced-q2lo2h, 180ms) var(--motion-easing-show-paced-ne2o0s, ease-out);
}
@keyframes awsui_awsui-motion-fade-in_xjuzf_hg5hv_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_container_xjuzf_hg5hv_730:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_container_xjuzf_hg5hv_730:not(#\9), .awsui-mode-entering .awsui_container_xjuzf_hg5hv_730:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_container_xjuzf_hg5hv_730.awsui_refresh_xjuzf_hg5hv_1041:not(#\9) {
  animation: awsui_awsui-motion-fade-in_xjuzf_hg5hv_1 var(--motion-duration-refresh-only-fast-4jn2d7, 115ms) var(--motion-easing-refresh-only-a-8flf9q, cubic-bezier(0, 0, 0, 1));
}
@keyframes awsui_awsui-motion-fade-in_xjuzf_hg5hv_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_container_xjuzf_hg5hv_730.awsui_refresh_xjuzf_hg5hv_1041:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_container_xjuzf_hg5hv_730.awsui_refresh_xjuzf_hg5hv_1041:not(#\9), .awsui-mode-entering .awsui_container_xjuzf_hg5hv_730.awsui_refresh_xjuzf_hg5hv_1041:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_xjuzf_hg5hv_1063:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  color: inherit;
}
.awsui_root_xjuzf_hg5hv_1063.awsui_no-wrap_xjuzf_hg5hv_1095:not(#\9) {
  white-space: nowrap;
}

.awsui_root-filtering-token_xjuzf_hg5hv_1099:not(#\9) {
  display: flex;
}

.awsui_trigger_xjuzf_hg5hv_1103:not(#\9) {
  display: inline-block;
  max-inline-size: 100%;
  color: inherit;
  text-align: inherit;
}

.awsui_overflow-ellipsis_xjuzf_hg5hv_1110:not(#\9) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-inline-size: 0;
  word-break: break-word;
}

.awsui_trigger-type-text_xjuzf_hg5hv_1118:not(#\9) {
  border-block: 0;
  border-inline: 0;
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  background-color: transparent;
  cursor: pointer;
  border-block-end: var(--border-divider-list-width-27y3k5, 1px) dashed currentColor;
}
.awsui_trigger-type-text_xjuzf_hg5hv_1118:not(#\9):focus {
  outline: none;
}
body[data-awsui-focus-visible=true] .awsui_trigger-type-text_xjuzf_hg5hv_1118:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_trigger-type-text_xjuzf_hg5hv_1118:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(1px - 1px);
}
body[data-awsui-focus-visible=true] .awsui_trigger-type-text_xjuzf_hg5hv_1118:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * 1px);
  inset-block-start: calc(-1 * 1px);
  inline-size: calc(100% + 1px + 1px);
  block-size: calc(100% + 1px + 1px);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_trigger-type-filtering-token_xjuzf_hg5hv_1154:not(#\9) {
  display: flex;
}

.awsui_popover-inline-content_xjuzf_hg5hv_1158:not(#\9) {
  display: inline;
}
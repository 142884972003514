/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_breadcrumb-group_d19fg_1p3cd_145:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  margin-block: 0;
  margin-inline: 0;
  padding-block: var(--space-xxs-p8yyaw, 4px);
  padding-inline: 0;
}
.awsui_breadcrumb-group_d19fg_1p3cd_145 > .awsui_breadcrumb-group-list_d19fg_1p3cd_180:not(#\9) {
  display: flex;
  align-items: center;
  padding-block: 0;
  padding-inline: 0;
  margin-block: 0;
  margin-inline: 0;
  list-style: none;
  inline-size: 100%;
  flex-wrap: nowrap;
}
.awsui_breadcrumb-group_d19fg_1p3cd_145 > .awsui_breadcrumb-group-list_d19fg_1p3cd_180.awsui_ghost_d19fg_1p3cd_191:not(#\9) {
  flex-wrap: wrap;
  position: absolute;
  inset-inline-start: -9000px;
}
.awsui_breadcrumb-group_d19fg_1p3cd_145 > .awsui_breadcrumb-group-list_d19fg_1p3cd_180 > .awsui_item_d19fg_1p3cd_196:not(#\9),
.awsui_breadcrumb-group_d19fg_1p3cd_145 > .awsui_breadcrumb-group-list_d19fg_1p3cd_180 > .awsui_ghost-item_d19fg_1p3cd_197:not(#\9),
.awsui_breadcrumb-group_d19fg_1p3cd_145 > .awsui_breadcrumb-group-list_d19fg_1p3cd_180 > .awsui_ellipsis_d19fg_1p3cd_198:not(#\9) {
  display: inline-block;
  padding-block: 0;
  padding-inline: 0;
  margin-block: 0;
  margin-inline: 0;
}
.awsui_breadcrumb-group_d19fg_1p3cd_145 > .awsui_breadcrumb-group-list_d19fg_1p3cd_180 > .awsui_item_d19fg_1p3cd_196:not(#\9):last-child {
  flex-shrink: 1;
  min-inline-size: 0;
}
.awsui_breadcrumb-group_d19fg_1p3cd_145 > .awsui_breadcrumb-group-list_d19fg_1p3cd_180 > .awsui_item_d19fg_1p3cd_196.awsui_hide_d19fg_1p3cd_209:not(#\9) {
  display: none;
}
.awsui_breadcrumb-group_d19fg_1p3cd_145 > .awsui_breadcrumb-group-list_d19fg_1p3cd_180 > .awsui_ellipsis_d19fg_1p3cd_198:not(#\9) {
  display: none;
}
.awsui_breadcrumb-group_d19fg_1p3cd_145 > .awsui_breadcrumb-group-list_d19fg_1p3cd_180 > .awsui_ellipsis_d19fg_1p3cd_198.awsui_visible_d19fg_1p3cd_215:not(#\9) {
  display: flex;
  flex-shrink: 0;
}
.awsui_breadcrumb-group_d19fg_1p3cd_145 > .awsui_breadcrumb-group-list_d19fg_1p3cd_180 > .awsui_ellipsis_d19fg_1p3cd_198 > .awsui_icon_d19fg_1p3cd_219:not(#\9) {
  margin-block: 0;
  margin-inline: 10px;
  color: var(--color-text-breadcrumb-icon-u5luu4, #8c8c94);
}

.awsui_breadcrumbs-skeleton_d19fg_1p3cd_225:not(#\9) {
  display: none;
}

.awsui_collapsed-button_d19fg_1p3cd_229:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  min-inline-size: 0;
  word-break: break-word;
  font-weight: var(--font-weight-button-c91p4t, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  letter-spacing: var(--font-button-letter-spacing-65ug0o, 0.005em);
  color: var(--color-text-interactive-default-tkx8fe, #424650);
  cursor: pointer;
  padding-block: 0;
  padding-inline: 0;
  border-inline: none;
  border-block: none;
  background: none;
  display: flex;
  gap: var(--space-xxs-p8yyaw, 4px);
  max-inline-size: 100%;
}
body[data-awsui-focus-visible=true] .awsui_collapsed-button_d19fg_1p3cd_229:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_collapsed-button_d19fg_1p3cd_229:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-cqfd0c, 4px) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_collapsed-button_d19fg_1p3cd_229:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  inset-block-start: calc(-1 * var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  inline-size: calc(100% + var(--space-button-focus-outline-gutter-cqfd0c, 4px) + var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  block-size: calc(100% + var(--space-button-focus-outline-gutter-cqfd0c, 4px) + var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_collapsed-button_d19fg_1p3cd_229:not(#\9):hover {
  color: var(--color-text-interactive-hover-f9gqs8, #0f141a);
}
.awsui_collapsed-button_d19fg_1p3cd_229 > :not(#\9):last-child {
  color: var(--color-text-breadcrumb-current-9h12sf, #656871);
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.awsui_collapsed-button_d19fg_1p3cd_229 > :not(#\9):last-child:hover {
  color: var(--color-text-interactive-hover-f9gqs8, #0f141a);
}

.awsui_button-icon_d19fg_1p3cd_311:not(#\9) {
  transform: rotate(0deg);
  transition: transform var(--motion-duration-rotate-180-dpvl4m, 135ms) var(--motion-easing-rotate-180-e270ko, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_button-icon_d19fg_1p3cd_311:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_button-icon_d19fg_1p3cd_311:not(#\9), .awsui-mode-entering .awsui_button-icon_d19fg_1p3cd_311:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_button-icon-open_d19fg_1p3cd_325:not(#\9) {
  transform: rotate(-180deg);
}

.awsui_hidden_d19fg_1p3cd_329:not(#\9) {
  display: none;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_icon_h11ix_1mfw9_189:not(#\9) {
  position: relative;
  display: inline-block;
  vertical-align: top;
  /* stylelint-disable-next-line selector-max-type */
  /* stylelint-disable selector-max-type, selector-max-universal */
  /* stylelint-enable selector-max-type, selector-max-universal */
}
.awsui_icon-flex-height_h11ix_1mfw9_197:not(#\9) {
  display: inline-flex;
  align-items: center;
}
.awsui_icon_h11ix_1mfw9_189 > svg:not(#\9) {
  pointer-events: none;
}
.awsui_icon_h11ix_1mfw9_189 > svg:not(#\9) {
  fill: none;
}
.awsui_icon_h11ix_1mfw9_189 > svg *:not(#\9) {
  stroke: currentColor;
}
.awsui_icon_h11ix_1mfw9_189 > svg .stroke-linejoin-round:not(#\9) {
  stroke-linejoin: round;
}
.awsui_icon_h11ix_1mfw9_189 > svg .stroke-linecap-square:not(#\9) {
  stroke-linecap: square;
}
.awsui_icon_h11ix_1mfw9_189 > svg .stroke-linecap-round:not(#\9) {
  stroke-linecap: round;
}
.awsui_icon_h11ix_1mfw9_189 > svg .filled:not(#\9) {
  fill: currentColor;
}
.awsui_icon_h11ix_1mfw9_189 > svg .no-stroke:not(#\9) {
  stroke: none;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-small_h11ix_1mfw9_225:not(#\9) {
  inline-size: var(--size-icon-normal-0m1722, 16px);
  box-sizing: border-box;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-small-mapped-height_h11ix_1mfw9_229:not(#\9) {
  block-size: var(--line-height-body-s-7zv1j5, 16px);
  padding-block: calc((var(--line-height-body-s-7zv1j5, 16px) - var(--size-icon-normal-0m1722, 16px)) / 2);
  padding-inline: 0;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-small_h11ix_1mfw9_225 > svg:not(#\9),
.awsui_icon_h11ix_1mfw9_189.awsui_size-small_h11ix_1mfw9_225 > img:not(#\9) {
  inline-size: var(--size-icon-normal-0m1722, 16px);
  block-size: var(--size-icon-normal-0m1722, 16px);
  vertical-align: top;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-small_h11ix_1mfw9_225 > svg:not(#\9),
.awsui_icon_h11ix_1mfw9_189.awsui_size-small_h11ix_1mfw9_225 > svg *:not(#\9) {
  stroke-width: 2px;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-normal_h11ix_1mfw9_244:not(#\9) {
  inline-size: var(--size-icon-normal-0m1722, 16px);
  box-sizing: border-box;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-normal-mapped-height_h11ix_1mfw9_248:not(#\9) {
  block-size: var(--line-height-body-m-30ar75, 20px);
  padding-block: calc((var(--line-height-body-m-30ar75, 20px) - var(--size-icon-normal-0m1722, 16px)) / 2);
  padding-inline: 0;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-normal_h11ix_1mfw9_244 > svg:not(#\9),
.awsui_icon_h11ix_1mfw9_189.awsui_size-normal_h11ix_1mfw9_244 > img:not(#\9) {
  inline-size: var(--size-icon-normal-0m1722, 16px);
  block-size: var(--size-icon-normal-0m1722, 16px);
  vertical-align: top;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-normal_h11ix_1mfw9_244 > svg:not(#\9),
.awsui_icon_h11ix_1mfw9_189.awsui_size-normal_h11ix_1mfw9_244 > svg *:not(#\9) {
  stroke-width: 2px;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-medium_h11ix_1mfw9_263:not(#\9) {
  inline-size: var(--size-icon-medium-y9xuoq, 20px);
  box-sizing: border-box;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-medium-mapped-height_h11ix_1mfw9_267:not(#\9) {
  block-size: var(--line-height-heading-l-mmm3my, 24px);
  padding-block: calc((var(--line-height-heading-l-mmm3my, 24px) - var(--size-icon-medium-y9xuoq, 20px)) / 2);
  padding-inline: 0;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-medium_h11ix_1mfw9_263 > svg:not(#\9),
.awsui_icon_h11ix_1mfw9_189.awsui_size-medium_h11ix_1mfw9_263 > img:not(#\9) {
  inline-size: var(--size-icon-medium-y9xuoq, 20px);
  block-size: var(--size-icon-medium-y9xuoq, 20px);
  vertical-align: top;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-medium_h11ix_1mfw9_263 > svg:not(#\9),
.awsui_icon_h11ix_1mfw9_189.awsui_size-medium_h11ix_1mfw9_263 > svg *:not(#\9) {
  stroke-width: 1.6px;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-big_h11ix_1mfw9_282:not(#\9) {
  inline-size: var(--size-icon-big-zkdryd, 32px);
  box-sizing: border-box;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-big-mapped-height_h11ix_1mfw9_286:not(#\9) {
  block-size: var(--line-height-heading-xl-avbttk, 30px);
  padding-block: calc((var(--line-height-heading-xl-avbttk, 30px) - var(--size-icon-big-zkdryd, 32px)) / 2);
  padding-inline: 0;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-big_h11ix_1mfw9_282 > svg:not(#\9),
.awsui_icon_h11ix_1mfw9_189.awsui_size-big_h11ix_1mfw9_282 > img:not(#\9) {
  inline-size: var(--size-icon-big-zkdryd, 32px);
  block-size: var(--size-icon-big-zkdryd, 32px);
  vertical-align: top;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-big_h11ix_1mfw9_282 > svg:not(#\9),
.awsui_icon_h11ix_1mfw9_189.awsui_size-big_h11ix_1mfw9_282 > svg *:not(#\9) {
  stroke-width: 1.5px;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-large_h11ix_1mfw9_301:not(#\9) {
  inline-size: var(--size-icon-large-bivhmh, 48px);
  box-sizing: border-box;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-large-mapped-height_h11ix_1mfw9_305:not(#\9) {
  block-size: var(--line-height-display-l-8nioft, 48px);
  padding-block: calc((var(--line-height-display-l-8nioft, 48px) - var(--size-icon-large-bivhmh, 48px)) / 2);
  padding-inline: 0;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-large_h11ix_1mfw9_301 > svg:not(#\9),
.awsui_icon_h11ix_1mfw9_189.awsui_size-large_h11ix_1mfw9_301 > img:not(#\9) {
  inline-size: var(--size-icon-large-bivhmh, 48px);
  block-size: var(--size-icon-large-bivhmh, 48px);
  vertical-align: top;
}
.awsui_icon_h11ix_1mfw9_189.awsui_size-large_h11ix_1mfw9_301 > svg:not(#\9),
.awsui_icon_h11ix_1mfw9_189.awsui_size-large_h11ix_1mfw9_301 > svg *:not(#\9) {
  stroke-width: 1.333px;
}
.awsui_icon_h11ix_1mfw9_189.awsui_variant-normal_h11ix_1mfw9_320:not(#\9) {
  color: currentColor;
}
.awsui_icon_h11ix_1mfw9_189.awsui_variant-disabled_h11ix_1mfw9_323:not(#\9) {
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
}
.awsui_icon_h11ix_1mfw9_189.awsui_variant-inverted_h11ix_1mfw9_326:not(#\9) {
  color: var(--color-text-inverted-pvc88w, #ffffff);
}
.awsui_icon_h11ix_1mfw9_189.awsui_variant-subtle_h11ix_1mfw9_329:not(#\9) {
  color: var(--color-text-icon-subtle-pt80lq, #656871);
}
.awsui_icon_h11ix_1mfw9_189.awsui_variant-warning_h11ix_1mfw9_332:not(#\9) {
  color: var(--color-text-status-warning-csaw41, #855900);
}
.awsui_icon_h11ix_1mfw9_189.awsui_variant-error_h11ix_1mfw9_335:not(#\9) {
  color: var(--color-text-status-error-5676bj, #db0000);
}
.awsui_icon_h11ix_1mfw9_189.awsui_variant-success_h11ix_1mfw9_338:not(#\9) {
  color: var(--color-text-status-success-gqp5xk, #00802f);
}
.awsui_icon_h11ix_1mfw9_189.awsui_variant-link_h11ix_1mfw9_341:not(#\9) {
  color: var(--color-text-link-default-enwvrt, #006ce0);
}
.awsui_icon_h11ix_1mfw9_189:not(#\9):is(.awsui_name-angle-left-double_h11ix_1mfw9_344,
.awsui_name-angle-left_h11ix_1mfw9_344,
.awsui_name-angle-right-double_h11ix_1mfw9_346,
.awsui_name-angle-right_h11ix_1mfw9_346,
.awsui_name-arrow-left_h11ix_1mfw9_348,
.awsui_name-caret-left-filled_h11ix_1mfw9_349,
.awsui_name-caret-right-filled_h11ix_1mfw9_350,
.awsui_name-audio-full_h11ix_1mfw9_351,
.awsui_name-audio-half_h11ix_1mfw9_352,
.awsui_name-audio-off_h11ix_1mfw9_353,
.awsui_name-external_h11ix_1mfw9_354,
.awsui_name-redo_h11ix_1mfw9_355,
.awsui_name-resize-area_h11ix_1mfw9_356,
.awsui_name-send_h11ix_1mfw9_357,
.awsui_name-shrink_h11ix_1mfw9_358,
.awsui_name-undo_h11ix_1mfw9_359,
.awsui_name-view-vertical_h11ix_1mfw9_360) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_icon_h11ix_1mfw9_189:not(#\9):is(.awsui_name-angle-left-double_h11ix_1mfw9_344,
.awsui_name-angle-left_h11ix_1mfw9_344,
.awsui_name-angle-right-double_h11ix_1mfw9_346,
.awsui_name-angle-right_h11ix_1mfw9_346,
.awsui_name-arrow-left_h11ix_1mfw9_348,
.awsui_name-caret-left-filled_h11ix_1mfw9_349,
.awsui_name-caret-right-filled_h11ix_1mfw9_350,
.awsui_name-audio-full_h11ix_1mfw9_351,
.awsui_name-audio-half_h11ix_1mfw9_352,
.awsui_name-audio-off_h11ix_1mfw9_353,
.awsui_name-external_h11ix_1mfw9_354,
.awsui_name-redo_h11ix_1mfw9_355,
.awsui_name-resize-area_h11ix_1mfw9_356,
.awsui_name-send_h11ix_1mfw9_357,
.awsui_name-shrink_h11ix_1mfw9_358,
.awsui_name-undo_h11ix_1mfw9_359,
.awsui_name-view-vertical_h11ix_1mfw9_360):dir(rtl) {
  transform: scaleX(-1);
}

.awsui_badge_h11ix_1mfw9_383:not(#\9)::after {
  content: "";
  position: absolute;
  inline-size: 6px;
  block-size: 6px;
  border-start-start-radius: 4px;
  border-start-end-radius: 4px;
  border-end-start-radius: 4px;
  border-end-end-radius: 4px;
  background-color: var(--color-background-badge-icon-n0lc3o, #db0000);
  inset-block-start: 0px;
  inset-inline-end: -3px;
}
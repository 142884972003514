/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
div.awsui_background_hyvsj_lgv7x_149:not(#\9) {
  display: contents;
}
div.awsui_background_hyvsj_lgv7x_149 > .awsui_scrolling-background_hyvsj_lgv7x_152:not(#\9) {
  background-color: var(--color-background-layout-main-nqw91u, #ffffff);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  grid-column: 1/span 5;
  grid-row: 1/9;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_breadcrumbs_hyvsj_lgv7x_163:not(#\9) {
  background-color: var(--color-background-layout-main-nqw91u, #ffffff);
  grid-area: breadcrumbs;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_drawers-container_hyvsj_lgv7x_176:not(#\9) {
  background-color: transparent;
  display: flex;
  grid-column: 5;
  grid-row: 1/span 9;
  block-size: var(--awsui-content-height-g964ok);
  pointer-events: none;
  position: sticky;
  inset-block-start: var(--awsui-offset-top-g964ok);
  z-index: 830;
}
.awsui_drawers-container_hyvsj_lgv7x_176.awsui_has-open-drawer_hyvsj_lgv7x_187:not(#\9) {
  background-color: var(--color-background-container-content-z79u4u, #ffffff);
}
@media (min-width: 689px) {
  .awsui_drawers-container_hyvsj_lgv7x_176:not(#\9) {
    max-inline-size: calc(var(--awsui-layout-width-g964ok) - var(--awsui-main-offset-left-g964ok) - var(--awsui-default-min-content-width-g964ok) - var(--awsui-content-gap-right-g964ok));
  }
}
@media (max-width: 688px) {
  .awsui_drawers-container_hyvsj_lgv7x_176:not(#\9) {
    position: fixed;
    inset-inline-end: 0;
    z-index: 1001;
    /*
    When disableBodyScroll is true the offsetTop will be relative to the 
    app layout and not the body. However, the drawer position changes 
    to fixed in mobile viewports. The top value needs to include the 
    header because fixed position switches the top value so it is now 
    relative to the body.
    */
  }
  .awsui_drawers-container_hyvsj_lgv7x_176.awsui_disable-body-scroll_hyvsj_lgv7x_208:not(#\9) {
    inset-block-start: var(--awsui-header-height-g964ok);
  }
}

.awsui_drawers-desktop-triggers-container_hyvsj_lgv7x_213:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  background-color: transparent;
  box-sizing: border-box;
  block-size: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  overscroll-behavior-y: contain;
}
.awsui_drawers-desktop-triggers-container_hyvsj_lgv7x_213:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_lgv7x_251).awsui_has-open-drawer_hyvsj_lgv7x_187 {
  inline-size: 0;
}
.awsui_drawers-desktop-triggers-container_hyvsj_lgv7x_213.awsui_has-multiple-triggers_hyvsj_lgv7x_251.awsui_has-open-drawer_hyvsj_lgv7x_187:not(#\9) {
  background-color: var(--color-background-container-content-z79u4u, #ffffff);
}
.awsui_drawers-desktop-triggers-container_hyvsj_lgv7x_213:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_lgv7x_251):not(.awsui_has-open-drawer_hyvsj_lgv7x_187) {
  inline-size: calc(var(--space-layout-toggle-padding-lcu6qi, 12px) * 2 + var(--space-layout-toggle-diameter-hb85rk, 36px));
}

.awsui_drawers-mobile-triggers-container_hyvsj_lgv7x_261:not(#\9) {
  display: flex;
  justify-content: flex-end;
}

.awsui_drawers-trigger-content_hyvsj_lgv7x_266:not(#\9) {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs-zb16t3, 8px);
  padding-block-start: var(--space-scaled-s-aqzyko, 12px);
  inline-size: calc(var(--space-layout-toggle-padding-lcu6qi, 12px) * 2 + var(--space-layout-toggle-diameter-hb85rk, 36px));
}
.awsui_drawers-trigger-content_hyvsj_lgv7x_266:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_lgv7x_251).awsui_has-open-drawer_hyvsj_lgv7x_187 {
  opacity: 0;
}
.awsui_drawers-trigger-content_hyvsj_lgv7x_266:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_lgv7x_251):not(.awsui_has-open-drawer_hyvsj_lgv7x_187) {
  opacity: 1;
}
.awsui_drawers-trigger-content_hyvsj_lgv7x_266 > .awsui_drawers-trigger-overflow_hyvsj_lgv7x_280:not(#\9) {
  padding-block: 0;
  padding-inline: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 688px) {
  .awsui_drawers-trigger_hyvsj_lgv7x_266:not(#\9) {
    inline-size: 40px;
    display: flex;
    justify-content: center;
  }
}

.awsui_drawer_hyvsj_lgv7x_176:not(#\9) {
  --awsui-drawer-size-g964ok: 290px;
  background-color: var(--color-background-container-content-z79u4u, #ffffff);
  border-color: transparent;
  display: grid;
  grid-template-columns: var(--space-m-udix3p, 16px) 1fr;
  flex-shrink: 0;
  block-size: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  overscroll-behavior-y: contain;
  pointer-events: auto;
  word-wrap: break-word;
}
.awsui_drawer_hyvsj_lgv7x_176 > .awsui_drawer-content-container_hyvsj_lgv7x_311:not(#\9) {
  grid-column: 1/span 2;
  grid-row: 1;
  inline-size: var(--awsui-drawer-size-g964ok);
  display: grid;
  grid-template-columns: var(--space-m-udix3p, 16px) 1fr auto var(--space-m-udix3p, 16px);
  grid-template-rows: var(--size-vertical-panel-icon-offset-wy8j08, 15px) auto 1fr;
  overflow-y: auto;
}
.awsui_drawer_hyvsj_lgv7x_176 > .awsui_drawer-content-container_hyvsj_lgv7x_311 > .awsui_drawer-close-button_hyvsj_lgv7x_320:not(#\9) {
  grid-column: 3;
  grid-row: 2;
  z-index: 1;
}
.awsui_drawer_hyvsj_lgv7x_176 > .awsui_drawer-content-container_hyvsj_lgv7x_311 > .awsui_drawer-content_hyvsj_lgv7x_311:not(#\9) {
  grid-column: 1/span 4;
  block-size: var(--awsui-content-height-g964ok);
}
.awsui_drawer_hyvsj_lgv7x_176 > .awsui_drawer-content-container_hyvsj_lgv7x_311 > .awsui_drawer-content_hyvsj_lgv7x_311.awsui_drawer-content-hidden_hyvsj_lgv7x_329:not(#\9) {
  display: none;
}
.awsui_drawer_hyvsj_lgv7x_176 > .awsui_drawer-slider_hyvsj_lgv7x_332:not(#\9) {
  grid-column: 1;
  grid-row: 1;
  block-size: 100%;
  display: flex;
  align-items: center;
}
.awsui_drawer_hyvsj_lgv7x_176:not(#\9):not(.awsui_is-drawer-open_hyvsj_lgv7x_339) {
  opacity: 0;
  inline-size: 0;
}
.awsui_drawer_hyvsj_lgv7x_176.awsui_is-drawer-open_hyvsj_lgv7x_339:not(#\9) {
  border-inline-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
  border-inline-start: solid var(--border-divider-section-width-1061zr, 1px) var(--color-border-divider-default-cx07f2, #c6c6cd);
  opacity: 1;
  inline-size: var(--awsui-drawer-size-g964ok);
}
@media (min-width: 2541px) {
  .awsui_drawer_hyvsj_lgv7x_176.awsui_is-drawer-open_hyvsj_lgv7x_339:not(#\9) {
    --awsui-drawer-size-g964ok: 320px;
  }
}
@media (max-width: 688px) {
  .awsui_drawer_hyvsj_lgv7x_176.awsui_is-drawer-open_hyvsj_lgv7x_339:not(#\9) {
    --awsui-drawer-size-g964ok: 100vw;
    inline-size: 100vw;
  }
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
header.awsui_content_hyvsj_lgv7x_365:not(#\9) {
  grid-area: header;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
The first and last column definitions have two responsibilities.
If Navigation and/or Tools exist then that will determine the width of
the first and last columns, respectively, and the content in these drawers
will create horizontal space from the center column content. However, if the
navigationHide and/or toolsHide properties have been set then the first
and last column width will default to the minimum content gap properties to
create the necessary visual space around the main content.

The minimum content width property is set to zero and applied under all
circumstances regardless of whether the minContentWidth property is
explicitly set in script.
*/
.awsui_layout_hyvsj_lgv7x_390:not(#\9) {
  --awsui-breadcrumbs-gap-g964ok: 0px;
  --awsui-content-gap-left-g964ok: 0px;
  --awsui-content-gap-right-g964ok: 0px;
  --awsui-content-height-g964ok: calc(100vh - var(--awsui-header-height-g964ok) - var(--awsui-footer-height-g964ok));
  --awsui-default-max-content-width-g964ok: 1280px;
  --awsui-default-min-content-width-g964ok: 0px;
  --awsui-footer-height-g964ok: 0px;
  --awsui-header-gap-g964ok: 0px;
  --awsui-header-height-g964ok: 0px;
  --awsui-layout-width-g964ok: 0px;
  --awsui-main-gap-g964ok: 0px;
  --awsui-main-offset-left-g964ok: 0px;
  --awsui-main-template-rows-g964ok: 1fr;
  --awsui-max-content-width-g964ok: 0px;
  --awsui-min-content-width-g964ok: 280px;
  --awsui-mobile-bar-height-g964ok: calc(2 * var(--space-m-udix3p, 16px) + var(--space-scaled-xs-26e2du, 8px));
  --awsui-notifications-height-g964ok: 0px;
  --awsui-offset-top-g964ok: var(--awsui-header-height-g964ok);
  --awsui-overlap-height-g964ok: var(--space-dark-header-overlap-distance-lxpp0s, 36px);
  --awsui-toggles-left-width-g964ok: 0px;
  --awsui-toggles-right-width-g964ok: 0px;
  background-color: var(--color-background-layout-main-nqw91u, #ffffff);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  display: grid;
  grid-template-areas: ". . mobileToolbar . ." ". . notifications . ." ". . breadcrumbsGap . ." ". . breadcrumbs . ." ". . headerGap . ." ". . header . ." ". . mainGap . ." ". . main . ." ". . main . .";
  grid-template-columns: min-content minmax(var(--awsui-content-gap-left-g964ok), 1fr) minmax(var(--awsui-default-min-content-width-g964ok), var(--awsui-default-max-content-width-g964ok)) minmax(var(--awsui-content-gap-right-g964ok), 1fr) min-content;
  grid-template-rows: auto auto var(--awsui-breadcrumbs-gap-g964ok) auto var(--awsui-header-gap-g964ok) auto var(--awsui-main-gap-g964ok) var(--awsui-overlap-height-g964ok) var(--awsui-main-template-rows-g964ok);
  min-block-size: var(--awsui-content-height-g964ok);
  position: relative;
  /*
  Add unified max-width for AppLayout content based on breakpoints. Only use the max content 
  width custom property for the middle column definition if the maxContentWidth property 
  has been explicitly set to a non-zero value and subsequently set the has-max-content-width 
  data attribute to true.
  */
  /*
  Only use the max content width custom property for the middle column definition
  if the maxContentWidth property has been explicitly set to a non-zero value and
  subsequently set the has-max-content-width data attribute to true.
  */
  /*
  Warning! This is a hack! We are implementing a short term fix to prevent the
  tools drawer from potentially getting pushed off screen. Currently there is no
  protection against a navigationWidth, minContentWidth, and toolsWidth that are
  in excess ove the available space in the viewport. To accomodate this we remove
  the minContentWidth and set it to zero in small viewports and below until the
  isMobile breakpoint sets the drawers to overlay at 100vw.
  */
  /*
  In desktop viewports the content gap property for the left and right
  columns should override the default of zero if the navigationHide
  or toolsHide property is set. This ensures adequate horizontal space
  for the center column from the edges of the viewport when there is
  not left or right content.

  Warning! If these design tokens change it will adversely impact the 
  calculation used to determine the Split Panel maximum width in the 
  handleSplitPanelMaxWidth function in the context.
  */
  /*
  In mobile viewports the Navigation and Tools drawers are hidden
  and triggered by the MobileToolbar which then take up the entire viewport.
  The center column needs permanent space from the horizontal viewport
  edges regardless of whether or not there is a Navigation or Tools.
  */
  /*
  Override the desktop gap values set above for the Notifications, Header, and Main
  components for mobile viewports.
  */
}
@media (min-width: 1401px) {
  .awsui_layout_hyvsj_lgv7x_390:not(#\9):not(.awsui_has-max-content-width_hyvsj_lgv7x_462) {
    --awsui-default-max-content-width-g964ok: 1280px;
  }
}
@media (min-width: 1921px) {
  .awsui_layout_hyvsj_lgv7x_390:not(#\9):not(.awsui_has-max-content-width_hyvsj_lgv7x_462) {
    --awsui-default-max-content-width-g964ok: 1440px;
  }
}
@media (min-width: 2541px) {
  .awsui_layout_hyvsj_lgv7x_390:not(#\9):not(.awsui_has-max-content-width_hyvsj_lgv7x_462) {
    --awsui-default-max-content-width-g964ok: 1620px;
  }
}
@media (min-width: 1401px) {
  .awsui_layout_hyvsj_lgv7x_390:not(#\9):not(.awsui_has-max-content-width_hyvsj_lgv7x_462).awsui_content-type-dashboard_hyvsj_lgv7x_477 {
    --awsui-default-max-content-width-g964ok: 1280px;
  }
}
@media (min-width: 1921px) {
  .awsui_layout_hyvsj_lgv7x_390:not(#\9):not(.awsui_has-max-content-width_hyvsj_lgv7x_462).awsui_content-type-dashboard_hyvsj_lgv7x_477 {
    --awsui-default-max-content-width-g964ok: 1620px;
  }
}
@media (min-width: 2541px) {
  .awsui_layout_hyvsj_lgv7x_390:not(#\9):not(.awsui_has-max-content-width_hyvsj_lgv7x_462).awsui_content-type-dashboard_hyvsj_lgv7x_477 {
    --awsui-default-max-content-width-g964ok: 2160px;
  }
}
.awsui_layout_hyvsj_lgv7x_390:not(#\9):not(.awsui_has-max-content-width_hyvsj_lgv7x_462).awsui_content-type-table_hyvsj_lgv7x_491, .awsui_layout_hyvsj_lgv7x_390:not(#\9):not(.awsui_has-max-content-width_hyvsj_lgv7x_462).awsui_content-type-cards_hyvsj_lgv7x_491 {
  --awsui-default-max-content-width-g964ok: 100%;
}
.awsui_layout_hyvsj_lgv7x_390.awsui_has-max-content-width_hyvsj_lgv7x_462:not(#\9) {
  --awsui-default-max-content-width-g964ok: var(--awsui-max-content-width-g964ok);
}
.awsui_layout_hyvsj_lgv7x_390.awsui_is-overlap-disabled_hyvsj_lgv7x_497:not(#\9) {
  --awsui-overlap-height-g964ok: 0;
}
.awsui_layout_hyvsj_lgv7x_390.awsui_is-hide-mobile-toolbar_hyvsj_lgv7x_500:not(#\9) {
  --awsui-mobile-bar-height-g964ok: 0px;
}
@media (min-width: 993px) {
  .awsui_layout_hyvsj_lgv7x_390:not(#\9) {
    --awsui-default-min-content-width-g964ok: var(--awsui-min-content-width-g964ok, 280px);
  }
}
@media (max-width: 992px) {
  .awsui_layout_hyvsj_lgv7x_390:not(#\9) {
    --awsui-default-min-content-width-g964ok: 0px;
  }
}
@media (min-width: 689px) {
  .awsui_layout_hyvsj_lgv7x_390.awsui_has-content-gap-left_hyvsj_lgv7x_514:not(#\9) {
    --awsui-content-gap-left-g964ok: var(--space-layout-content-horizontal-7l52k3, 24px);
  }
  .awsui_layout_hyvsj_lgv7x_390.awsui_has-content-gap-right_hyvsj_lgv7x_517:not(#\9) {
    --awsui-content-gap-right-g964ok: var(--space-layout-content-horizontal-7l52k3, 24px);
  }
}
@media (max-width: 688px) {
  .awsui_layout_hyvsj_lgv7x_390:not(#\9) {
    --awsui-content-gap-left-g964ok: var(--space-l-t419sm, 20px);
    --awsui-content-gap-right-g964ok: var(--space-l-t419sm, 20px);
  }
}
.awsui_layout_hyvsj_lgv7x_390.awsui_has-breadcrumbs_hyvsj_lgv7x_527:not(#\9) {
  --awsui-breadcrumbs-gap-g964ok: var(--space-scaled-m-mo5yse, 16px);
}
.awsui_layout_hyvsj_lgv7x_390.awsui_content-first-child-header_hyvsj_lgv7x_530:not(#\9), .awsui_layout_hyvsj_lgv7x_390.awsui_content-first-child-notifications_hyvsj_lgv7x_530:not(#\9):not(.awsui_has-breadcrumbs_hyvsj_lgv7x_527).awsui_has-header_hyvsj_lgv7x_530 {
  --awsui-header-gap-g964ok: var(--space-scaled-xs-26e2du, 8px);
}
.awsui_layout_hyvsj_lgv7x_390.awsui_has-breadcrumbs_hyvsj_lgv7x_527.awsui_has-header_hyvsj_lgv7x_530:not(#\9) {
  --awsui-header-gap-g964ok: var(--space-scaled-xs-26e2du, 8px);
}
.awsui_layout_hyvsj_lgv7x_390.awsui_content-first-child-notifications_hyvsj_lgv7x_530:not(#\9):not(.awsui_has-breadcrumbs_hyvsj_lgv7x_527):not(.awsui_has-header_hyvsj_lgv7x_530) {
  --awsui-main-gap-g964ok: var(--space-xs-zb16t3, 8px);
}
.awsui_layout_hyvsj_lgv7x_390.awsui_has-breadcrumbs_hyvsj_lgv7x_527:not(#\9):not(.awsui_has-header_hyvsj_lgv7x_530) {
  --awsui-main-gap-g964ok: var(--space-scaled-xxs-7597g1, 4px);
}
.awsui_layout_hyvsj_lgv7x_390.awsui_has-header_hyvsj_lgv7x_530:not(#\9) {
  --awsui-main-gap-g964ok: var(--space-content-header-padding-bottom-y2enua, 16px);
}
.awsui_layout_hyvsj_lgv7x_390.awsui_content-first-child-main_hyvsj_lgv7x_545:not(#\9):not(.awsui_disable-content-paddings_hyvsj_lgv7x_545) {
  --awsui-main-gap-g964ok: var(--space-scaled-s-aqzyko, 12px);
}
.awsui_layout_hyvsj_lgv7x_390.awsui_content-first-child-main_hyvsj_lgv7x_545.awsui_disable-content-paddings_hyvsj_lgv7x_545:not(#\9) {
  --awsui-main-gap-g964ok: 0px;
}
@media (max-width: 688px) {
  .awsui_layout_hyvsj_lgv7x_390.awsui_content-first-child-header_hyvsj_lgv7x_530:not(#\9) {
    --awsui-header-gap-g964ok: var(--space-scaled-s-aqzyko, 12px);
  }
  .awsui_layout_hyvsj_lgv7x_390.awsui_content-first-child-notifications_hyvsj_lgv7x_530:not(#\9):not(.awsui_has-breadcrumbs_hyvsj_lgv7x_527).awsui_has-header_hyvsj_lgv7x_530 {
    --awsui-header-gap-g964ok: var(--space-scaled-s-aqzyko, 12px);
  }
  .awsui_layout_hyvsj_lgv7x_390.awsui_content-first-child-notifications_hyvsj_lgv7x_530:not(#\9):not(.awsui_has-breadcrumbs_hyvsj_lgv7x_527):not(.awsui_has-header_hyvsj_lgv7x_530), .awsui_layout_hyvsj_lgv7x_390.awsui_content-first-child-main_hyvsj_lgv7x_545:not(#\9):not(.awsui_disable-content-paddings_hyvsj_lgv7x_545) {
    --awsui-main-gap-g964ok: var(--space-scaled-s-aqzyko, 12px);
  }
  .awsui_layout_hyvsj_lgv7x_390.awsui_content-first-child-main_hyvsj_lgv7x_545.awsui_disable-content-paddings_hyvsj_lgv7x_545:not(#\9) {
    --awsui-main-gap-g964ok: 0px;
  }
}
.awsui_layout_hyvsj_lgv7x_390.awsui_has-left-toggles-gutter_hyvsj_lgv7x_565:not(#\9) {
  --awsui-toggles-left-width-g964ok: calc(var(--space-layout-toggle-padding-lcu6qi, 12px) + 36px);
}
.awsui_layout_hyvsj_lgv7x_390.awsui_has-right-toggles-gutter_hyvsj_lgv7x_568:not(#\9) {
  --awsui-toggles-right-width-g964ok: calc(var(--space-layout-toggle-padding-lcu6qi, 12px) + 36px);
}

/*
If disableBodyScroll is true (very uncommon use case) then the AppLayout component 
and not the document body will be the scrollable element. This requires a fixed height 
instead of a minimum height with a vertical scroll policy. The offset top value for 
components with position: sticky (MobileToolbar, Navigation, Notifications, Tools) will be 
set zero since the AppLayout is nearest scrollable parent and any existing header does 
not matter. The offset top value for AppLayout contentMain children with 
position: sticky will be the value of the notifications height in addition to the 
notifications top margin and some additional vertical space for aesthetics.
*/
.awsui_layout_hyvsj_lgv7x_390.awsui_disable-body-scroll_hyvsj_lgv7x_208:not(#\9) {
  --awsui-main-template-rows-g964ok: 1fr auto;
  --awsui-offset-top-g964ok: 0px;
  block-size: var(--awsui-content-height-g964ok);
  overflow-y: scroll;
}
.awsui_layout_hyvsj_lgv7x_390.awsui_disable-body-scroll_hyvsj_lgv7x_208.awsui_has-split-panel_hyvsj_lgv7x_588.awsui_split-panel-position-bottom_hyvsj_lgv7x_588:not(#\9) {
  --awsui-main-template-rows-g964ok: repeat(2, auto);
}

/*
This CSS class is applied to the document body to prevent overflow scrolling
when the navigation or tools drawers are open in responsive viewports.
*/
.awsui_block-body-scroll_hyvsj_lgv7x_596:not(#\9) {
  overflow: hidden;
}

/* stylelint-disable selector-max-universal, selector-combinator-disallowed-list */
.awsui_unfocusable_hyvsj_lgv7x_601:not(#\9),
.awsui_unfocusable_hyvsj_lgv7x_601 *:not(#\9) {
  visibility: hidden !important;
}

/* stylelint-enable selector-max-universal, selector-combinator-disallowed-list */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_container_hyvsj_lgv7x_611:not(#\9) {
  grid-area: main;
  padding-block-end: var(--space-layout-content-bottom-noqzmw, 40px);
  /*
  If the split panel is in the bottom position additional padding will need to be
  added to the content area. This is to ensure that the user is able to scroll
  far enough to see all of the content that would otherwise be obscured by the
  sticky position of the split panel.
  */
  /*
  If disableContentPaddings is enabled then the Main content has a different
  behavior inside the Layout grid. By default it will render across the entire
  grid column span. If the Navigation is open on the left, we increment the
  start column by one. If the Tools or Split Panel (in side position) is open
  on the right, we decrement the column end by one.
  */
}
.awsui_container_hyvsj_lgv7x_611.awsui_has-split-panel_hyvsj_lgv7x_588.awsui_split-panel-position-bottom_hyvsj_lgv7x_588:not(#\9) {
  padding-block-end: calc(var(--awsui-split-panel-height-g964ok) + var(--space-layout-content-bottom-noqzmw, 40px));
}
.awsui_container_hyvsj_lgv7x_611.awsui_disable-content-paddings_hyvsj_lgv7x_545:not(#\9) {
  grid-column: 1/6;
  padding-block: 0;
  padding-inline: 0;
}
@media (min-width: 689px) {
  .awsui_container_hyvsj_lgv7x_611.awsui_disable-content-paddings_hyvsj_lgv7x_545.awsui_is-navigation-open_hyvsj_lgv7x_637:not(#\9) {
    grid-column-start: 2;
  }
  .awsui_container_hyvsj_lgv7x_611.awsui_disable-content-paddings_hyvsj_lgv7x_545.awsui_is-tools-open_hyvsj_lgv7x_640:not(#\9), .awsui_container_hyvsj_lgv7x_611.awsui_disable-content-paddings_hyvsj_lgv7x_545.awsui_is-split-panel-open_hyvsj_lgv7x_640.awsui_split-panel-position-side_hyvsj_lgv7x_640:not(#\9), .awsui_container_hyvsj_lgv7x_611.awsui_disable-content-paddings_hyvsj_lgv7x_545.awsui_has-active-drawer_hyvsj_lgv7x_640:not(#\9) {
    grid-column-end: 5;
  }
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
section.awsui_mobile-toolbar_hyvsj_lgv7x_653:not(#\9) {
  align-items: center;
  background-color: var(--color-background-layout-main-nqw91u, #ffffff);
  border-block-end: 1px solid var(--color-border-divider-default-cx07f2, #c6c6cd);
  box-shadow: var(--shadow-sticky-blixp9, 0px 4px 8px 1px rgba(0, 7, 22, 0.1));
  box-sizing: border-box;
  block-size: var(--awsui-mobile-bar-height-g964ok);
  display: grid;
  grid-area: mobileToolbar;
  grid-column: 1/span 5;
  grid-template-columns: auto minmax(0, 1fr) auto;
  padding-block: 0;
  padding-inline: var(--space-m-udix3p, 16px);
  position: sticky;
  inset-block-start: var(--awsui-offset-top-g964ok);
  z-index: 1000;
}
section.awsui_mobile-toolbar_hyvsj_lgv7x_653:not(#\9):not(.awsui_remove-high-contrast-header_hyvsj_lgv7x_670) {
  background-color: var(--color-background-layout-main-nqw91u, #ffffff);
  box-shadow: var(--shadow-panel-toggle-0l64f2, 0px 6px 12px 1px rgba(0, 7, 22, 0.12));
}
section.awsui_mobile-toolbar_hyvsj_lgv7x_653 > .awsui_mobile-toolbar-nav_hyvsj_lgv7x_674:not(#\9) {
  grid-column: 1;
  margin-inline-end: var(--space-m-udix3p, 16px);
}
section.awsui_mobile-toolbar_hyvsj_lgv7x_653 > .awsui_mobile-toolbar-breadcrumbs_hyvsj_lgv7x_678:not(#\9) {
  grid-column: 2;
  background-color: var(--color-background-layout-main-nqw91u, #ffffff);
}
section.awsui_mobile-toolbar_hyvsj_lgv7x_653 > .awsui_mobile-toolbar-tools_hyvsj_lgv7x_682:not(#\9) {
  grid-column: 3;
  margin-inline-start: var(--space-m-udix3p, 16px);
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_navigation-container_hyvsj_lgv7x_691:not(#\9) {
  display: flex;
  grid-column: 1;
  grid-row: 1/span 9;
  block-size: var(--awsui-content-height-g964ok);
  position: sticky;
  inset-block-start: var(--awsui-offset-top-g964ok);
  z-index: 830;
  /*
  The navigation and tools containers (that contain the toggle buttons)
  stretch the full height of the app layout. Normally, this wouldn't be an
  issue because they sit above the app layout's content padding.

  But if disableContentPaddings is set to true and there are buttons on the
  left/right edges of the screen, they will be covered by the containers. So
  we need to disable pointer events in the container and re-enable them in
  the panels and toggle buttons.
  */
  pointer-events: none;
}
@media (max-width: 688px) {
  .awsui_navigation-container_hyvsj_lgv7x_691:not(#\9) {
    inset-inline-start: 0;
    position: fixed;
    z-index: 1001;
    /*
    When disableBodyScroll is true the offsetTop will be relative to the 
    app layout and not the body. However, the drawer position changes 
    to fixed in mobile viewports. The top value needs to include the 
    header because fixed position switches the top value so it is now 
    relative to the body.
    */
  }
  .awsui_navigation-container_hyvsj_lgv7x_691.awsui_disable-body-scroll_hyvsj_lgv7x_208:not(#\9) {
    inset-block-start: var(--awsui-header-height-g964ok);
  }
}

nav.awsui_show-navigation_hyvsj_lgv7x_729:not(#\9) {
  padding-block: var(--space-scaled-s-aqzyko, 12px);
  padding-inline: var(--space-layout-toggle-padding-lcu6qi, 12px);
  /*
  Apply the animation only in desktop viewports because the MobileToolbar will
  take control in responsive viewports.
  */
}
@keyframes awsui_showButtons_hyvsj_lgv7x_1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
nav.awsui_show-navigation_hyvsj_lgv7x_729.awsui_is-navigation-open_hyvsj_lgv7x_637:not(#\9) {
  display: none;
}
@media (min-width: 689px) {
  nav.awsui_show-navigation_hyvsj_lgv7x_729:not(#\9):not(.awsui_is-navigation-open_hyvsj_lgv7x_637) {
    display: block;
  }
  nav.awsui_show-navigation_hyvsj_lgv7x_729:not(#\9):not(.awsui_is-navigation-open_hyvsj_lgv7x_637).awsui_animating_hyvsj_lgv7x_752 {
    animation: awsui_showButtons_hyvsj_lgv7x_1 var(--motion-duration-refresh-only-fast-4jn2d7, 115ms);
  }
}
@media (min-width: 689px) and (prefers-reduced-motion: reduce) {
  nav.awsui_show-navigation_hyvsj_lgv7x_729:not(#\9):not(.awsui_is-navigation-open_hyvsj_lgv7x_637).awsui_animating_hyvsj_lgv7x_752 {
    animation: none;
    transition: none;
  }
}
@media (min-width: 689px) {
  .awsui-motion-disabled nav.awsui_show-navigation_hyvsj_lgv7x_729:not(#\9):not(.awsui_is-navigation-open_hyvsj_lgv7x_637).awsui_animating_hyvsj_lgv7x_752, .awsui-mode-entering nav.awsui_show-navigation_hyvsj_lgv7x_729:not(#\9):not(.awsui_is-navigation-open_hyvsj_lgv7x_637).awsui_animating_hyvsj_lgv7x_752 {
    animation: none;
    transition: none;
  }
}

nav.awsui_navigation_hyvsj_lgv7x_691:not(#\9) {
  background-color: var(--color-background-container-content-z79u4u, #ffffff);
  inset-block-end: 0;
  block-size: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  overscroll-behavior-y: contain;
  position: relative;
  word-wrap: break-word;
  pointer-events: auto;
  border-inline-end: solid var(--border-divider-section-width-1061zr, 1px) var(--color-border-divider-default-cx07f2, #c6c6cd);
  display: flex;
  flex-direction: column;
  /*
  A non-semantic node is added with a fixed width equal to the final Navigation
  width. This will create the visual appearance of horizontal movement and
  prevent unwanted text wrapping.
  */
}
@keyframes awsui_openNavigation_hyvsj_lgv7x_1 {
  from {
    opacity: 0;
    inline-size: calc(var(--space-layout-toggle-padding-lcu6qi, 12px) * 2 + var(--space-layout-toggle-diameter-hb85rk, 36px));
  }
  to {
    opacity: 1;
    inline-size: var(--awsui-navigation-width-g964ok);
  }
}
nav.awsui_navigation_hyvsj_lgv7x_691:not(#\9):not(.awsui_is-navigation-open_hyvsj_lgv7x_637) {
  inline-size: 0;
  display: none;
}
nav.awsui_navigation_hyvsj_lgv7x_691.awsui_is-navigation-open_hyvsj_lgv7x_637.awsui_animating_hyvsj_lgv7x_752:not(#\9) {
  animation: awsui_openNavigation_hyvsj_lgv7x_1 var(--motion-duration-refresh-only-fast-4jn2d7, 115ms);
}
@media (prefers-reduced-motion: reduce) {
  nav.awsui_navigation_hyvsj_lgv7x_691.awsui_is-navigation-open_hyvsj_lgv7x_637.awsui_animating_hyvsj_lgv7x_752:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled nav.awsui_navigation_hyvsj_lgv7x_691.awsui_is-navigation-open_hyvsj_lgv7x_637.awsui_animating_hyvsj_lgv7x_752:not(#\9), .awsui-mode-entering nav.awsui_navigation_hyvsj_lgv7x_691.awsui_is-navigation-open_hyvsj_lgv7x_637.awsui_animating_hyvsj_lgv7x_752:not(#\9) {
  animation: none;
  transition: none;
}
nav.awsui_navigation_hyvsj_lgv7x_691 > .awsui_animated-content_hyvsj_lgv7x_816:not(#\9) {
  inline-size: var(--awsui-navigation-width-g964ok);
}
nav.awsui_navigation_hyvsj_lgv7x_691 > .awsui_content-container_hyvsj_lgv7x_819:not(#\9) {
  flex-grow: 1;
}
@media (max-width: 688px) {
  nav.awsui_navigation_hyvsj_lgv7x_691:not(#\9) {
    --awsui-navigation-width-g964ok: 100vw;
  }
}

.awsui_hide-navigation_hyvsj_lgv7x_828:not(#\9) {
  position: absolute;
  inset-inline-end: var(--space-m-udix3p, 16px);
  inset-block-start: var(--size-vertical-panel-icon-offset-wy8j08, 15px);
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_notifications_hyvsj_lgv7x_838:not(#\9) {
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  grid-area: notifications;
  z-index: 850;
}
.awsui_notifications_hyvsj_lgv7x_838.awsui_has-notification-content_hyvsj_lgv7x_843:not(#\9) {
  padding-block-start: var(--space-scaled-s-aqzyko, 12px);
}
@media (min-width: 689px) {
  .awsui_notifications_hyvsj_lgv7x_838.awsui_sticky-notifications_hyvsj_lgv7x_847:not(#\9) {
    --awsui-flashbar-sticky-bottom-margin-g964ok: var(--space-xxl-cu2m1r, 32px);
    position: sticky;
    inset-block-start: var(--awsui-offset-top-g964ok);
  }
  .awsui_notifications_hyvsj_lgv7x_838.awsui_sticky-notifications_hyvsj_lgv7x_847:not(#\9):not(.awsui_high-contrast_hyvsj_lgv7x_852) {
    background-color: var(--color-background-layout-main-nqw91u, #ffffff);
  }
  .awsui_notifications_hyvsj_lgv7x_838.awsui_has-notification-content_hyvsj_lgv7x_843:not(#\9) {
    padding-block-start: var(--space-xs-zb16t3, 8px);
  }
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
When the Split Panel is in the bottom position it was share the same row
as the content area. This row is defined as 1 fractional unit which will
consume the remaining vertical space in the grid after the notifications
and breadcrumbs.
*/
section.awsui_split-panel-bottom_hyvsj_lgv7x_870:not(#\9) {
  /*
  The align self property will position the split panel at the bottom of the grid row.
  This could be off the viewport if the content area has enough content to be scrollable.
  */
  align-self: end;
  inset-block-end: var(--awsui-footer-height-g964ok);
  display: none;
  grid-column: 1/6;
  grid-row: 9;
  block-size: auto;
  overflow-y: hidden;
  /*
  The position sticky will work in conjunction with the align self: end; property.
  If the grid row scrolls beyond the viewport, the sticky bottom position
  will lift it up above the footer so it is always visible.
  */
  position: sticky;
  z-index: 840;
  /*
  Unlike the side position the Split Panel is persistent in the DOM
  when in the bottom position.
  */
  /*
  Warning! This is a hack! The existing design token for the split panel
  shadow in the bottom position does not render in the refactored code.
  It appears to be related to the fact that the legacy split panel element
  has a height equal to the expanded height and a corresponding translation
  of the Y position so it is moved off the screen. This will need to be
  refactored with an adjustment to the split panel design token.
  */
  /*
  When the data attribute changes indicating the Split Panel has been opened
  apply the animation to the height property.
  */
}
@keyframes awsui_openSplitPanelBottom_hyvsj_lgv7x_1 {
  from {
    block-size: var(--awsui-split-panel-reported-header-size-g964ok, 0);
  }
  to {
    block-size: var(--awsui-split-panel-reported-size-g964ok);
  }
}
section.awsui_split-panel-bottom_hyvsj_lgv7x_870.awsui_disable-body-scroll_hyvsj_lgv7x_208:not(#\9) {
  inset-block-end: 0;
}
section.awsui_split-panel-bottom_hyvsj_lgv7x_870.awsui_is-navigation-open_hyvsj_lgv7x_637.awsui_position-bottom_hyvsj_lgv7x_917:not(#\9) {
  grid-column-start: 2;
}
section.awsui_split-panel-bottom_hyvsj_lgv7x_870.awsui_has-open-drawer_hyvsj_lgv7x_187.awsui_position-bottom_hyvsj_lgv7x_917:not(#\9) {
  grid-column-end: 5;
}
section.awsui_split-panel-bottom_hyvsj_lgv7x_870.awsui_position-bottom_hyvsj_lgv7x_917:not(#\9) {
  display: block;
}
section.awsui_split-panel-bottom_hyvsj_lgv7x_870:not(#\9):not(.awsui_is-split-panel-open_hyvsj_lgv7x_640).awsui_position-bottom_hyvsj_lgv7x_917 {
  box-shadow: rgba(0, 7, 22, 0.1) 0px -32px 32px -24px;
}
section.awsui_split-panel-bottom_hyvsj_lgv7x_870.awsui_is-split-panel-open_hyvsj_lgv7x_640.awsui_position-bottom_hyvsj_lgv7x_917:not(#\9) {
  box-shadow: var(--shadow-split-bottom-5e389u, 0px -36px 36px -36px rgba(0, 7, 22, 0.1));
}
section.awsui_split-panel-bottom_hyvsj_lgv7x_870.awsui_is-split-panel-open_hyvsj_lgv7x_640.awsui_position-bottom_hyvsj_lgv7x_917.awsui_animating_hyvsj_lgv7x_752:not(#\9) {
  animation: awsui_openSplitPanelBottom_hyvsj_lgv7x_1 var(--motion-duration-refresh-only-fast-4jn2d7, 115ms);
}
@media (prefers-reduced-motion: reduce) {
  section.awsui_split-panel-bottom_hyvsj_lgv7x_870.awsui_is-split-panel-open_hyvsj_lgv7x_640.awsui_position-bottom_hyvsj_lgv7x_917.awsui_animating_hyvsj_lgv7x_752:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled section.awsui_split-panel-bottom_hyvsj_lgv7x_870.awsui_is-split-panel-open_hyvsj_lgv7x_640.awsui_position-bottom_hyvsj_lgv7x_917.awsui_animating_hyvsj_lgv7x_752:not(#\9), .awsui-mode-entering section.awsui_split-panel-bottom_hyvsj_lgv7x_870.awsui_is-split-panel-open_hyvsj_lgv7x_640.awsui_position-bottom_hyvsj_lgv7x_917.awsui_animating_hyvsj_lgv7x_752:not(#\9) {
  animation: none;
  transition: none;
}

section.awsui_split-panel-side_hyvsj_lgv7x_946:not(#\9) {
  block-size: 100%;
  overflow-x: hidden;
  pointer-events: auto;
  /*
  The min and max widths are applied when the Split Panel is opened otherwise
  it would not be possible to animate the width and the box shadow would
  be persistent in the DOM when closed.
  */
}
section.awsui_split-panel-side_hyvsj_lgv7x_946:not(#\9):not(.awsui_is-split-panel-open_hyvsj_lgv7x_640), section.awsui_split-panel-side_hyvsj_lgv7x_946.awsui_position-bottom_hyvsj_lgv7x_917:not(#\9) {
  inline-size: 0;
}
section.awsui_split-panel-side_hyvsj_lgv7x_946.awsui_is-split-panel-open_hyvsj_lgv7x_640.awsui_position-side_hyvsj_lgv7x_959:not(#\9) {
  max-inline-size: var(--awsui-split-panel-max-width-g964ok, 280px);
  min-inline-size: var(--awsui-split-panel-min-width-g964ok, 280px);
  border-inline-start: solid var(--border-divider-section-width-1061zr, 1px) var(--color-border-divider-default-cx07f2, #c6c6cd);
}
section.awsui_split-panel-side_hyvsj_lgv7x_946.awsui_is-split-panel-open_hyvsj_lgv7x_640.awsui_position-side_hyvsj_lgv7x_959:not(#\9):not(.awsui_has-open-drawer_hyvsj_lgv7x_187) {
  border-inline-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-panel-side-j1ubnj, #c6c6cd);
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
The Tools component container has a max width calculation that depends on the
state of the other siblings within the Layout grid definition. The layout width
is set in the resize observer in the Layout component. The main offset left
will calculate the distance from the start of the Layout component. The minimum
content width has a default value that can be set directly with the minContentWidth
property. The content gap right is computed in the Layout styles based on the
viewport size and state of the Tools drawer.
*/
.awsui_tools-container_hyvsj_lgv7x_981:not(#\9) {
  --awsui-tools-max-width-g964ok: calc(var(--awsui-layout-width-g964ok) - var(--awsui-main-offset-left-g964ok) - var(--awsui-default-min-content-width-g964ok) - var(--awsui-content-gap-right-g964ok));
  display: flex;
  grid-column: 5;
  grid-row: 1/span 9;
  block-size: var(--awsui-content-height-g964ok);
  max-inline-size: var(--awsui-tools-max-width-g964ok);
  position: sticky;
  inset-block-start: var(--awsui-offset-top-g964ok);
  z-index: 830;
  pointer-events: none;
}
@media (max-width: 688px) {
  .awsui_tools-container_hyvsj_lgv7x_981:not(#\9) {
    --awsui-tools-max-width-g964ok: none;
    --awsui-tools-width-g964ok: auto;
    position: fixed;
    inset-inline-end: 0;
    z-index: 1001;
    /*
    When disableBodyScroll is true the offsetTop will be relative to the 
    app layout and not the body. However, the drawer position changes 
    to fixed in mobile viewports. The top value needs to include the 
    header because fixed position switches the top value so it is now 
    relative to the body.
    */
  }
  .awsui_tools-container_hyvsj_lgv7x_981.awsui_disable-body-scroll_hyvsj_lgv7x_208:not(#\9) {
    inset-block-start: var(--awsui-header-height-g964ok);
  }
}

.awsui_tools_hyvsj_lgv7x_981:not(#\9) {
  background-color: var(--color-background-container-content-z79u4u, #ffffff);
  flex-shrink: 0;
  block-size: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  overscroll-behavior-y: contain;
  pointer-events: auto;
  position: relative;
  word-wrap: break-word;
  /*
  A non-semantic node is added with a fixed width equal to the final Tools
  width. This will create the visual appearance of horizontal movement and
  prevent unwanted text wrapping.
  */
  /*
  A right border is needed if the Tools is open and the buttons are persistent in
  the DOM. This creates a visual vertical boundary between the Tools and the Buttons
  only when they are both present. This is the circumstance when there is a Split Panel
  in the side position.
  */
}
@keyframes awsui_openTools_hyvsj_lgv7x_1 {
  from {
    opacity: var(--awsui-tools-animation-starting-opacity-g964ok, 0);
    inline-size: calc(var(--space-layout-toggle-padding-lcu6qi, 12px) * 2 + var(--space-layout-toggle-diameter-hb85rk, 36px));
  }
  to {
    opacity: 1;
    inline-size: var(--awsui-tools-width-g964ok);
  }
}
.awsui_tools_hyvsj_lgv7x_981:not(#\9):not(.awsui_is-tools-open_hyvsj_lgv7x_640) {
  inline-size: 0;
  display: none;
}
.awsui_tools_hyvsj_lgv7x_981.awsui_is-tools-open_hyvsj_lgv7x_640:not(#\9) {
  border-inline-start: solid var(--border-divider-section-width-1061zr, 1px) var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_tools_hyvsj_lgv7x_981.awsui_is-tools-open_hyvsj_lgv7x_640.awsui_animating_hyvsj_lgv7x_752:not(#\9) {
  animation: awsui_openTools_hyvsj_lgv7x_1 var(--motion-duration-refresh-only-fast-4jn2d7, 115ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_tools_hyvsj_lgv7x_981.awsui_is-tools-open_hyvsj_lgv7x_640.awsui_animating_hyvsj_lgv7x_752:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_tools_hyvsj_lgv7x_981.awsui_is-tools-open_hyvsj_lgv7x_640.awsui_animating_hyvsj_lgv7x_752:not(#\9), .awsui-mode-entering .awsui_tools_hyvsj_lgv7x_981.awsui_is-tools-open_hyvsj_lgv7x_640.awsui_animating_hyvsj_lgv7x_752:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_tools_hyvsj_lgv7x_981 > .awsui_animated-content_hyvsj_lgv7x_816:not(#\9) {
  inline-size: var(--awsui-tools-width-g964ok);
}
@media (min-width: 689px) {
  .awsui_tools_hyvsj_lgv7x_981.awsui_is-tools-open_hyvsj_lgv7x_640.awsui_has-tools-form-persistence_hyvsj_lgv7x_1070:not(#\9) {
    border-inline-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
  }
}
@media (max-width: 688px) {
  .awsui_tools_hyvsj_lgv7x_981:not(#\9) {
    --awsui-tools-width-g964ok: 100vw;
  }
}

.awsui_hide-tools_hyvsj_lgv7x_1080:not(#\9) {
  position: absolute;
  inset-inline-end: var(--space-m-udix3p, 16px);
  inset-block-start: var(--size-vertical-panel-icon-offset-wy8j08, 15px);
  z-index: 1;
}

/*
Warning! If these design tokens for padding change it will adversely impact
the calculation used to determine the Split Panel maximum width in the
handleSplitPanelMaxWidth function in the context.
*/
.awsui_show-tools_hyvsj_lgv7x_1092:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  box-sizing: border-box;
  padding-block: var(--space-scaled-s-aqzyko, 12px);
  padding-inline: var(--space-layout-toggle-padding-lcu6qi, 12px);
  /*
  Apply the animation only in desktop viewports because the MobileToolbar will 
  take control in responsive viewports.
  */
}
@keyframes awsui_showButtons_hyvsj_lgv7x_1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.awsui_show-tools_hyvsj_lgv7x_1092:not(#\9):not(.awsui_has-tools-form_hyvsj_lgv7x_1070) {
  display: none;
}
@media (min-width: 689px) {
  .awsui_show-tools_hyvsj_lgv7x_1092.awsui_has-tools-form_hyvsj_lgv7x_1070:not(#\9) {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs-zb16t3, 8px);
  }
  .awsui_show-tools_hyvsj_lgv7x_1092.awsui_has-tools-form_hyvsj_lgv7x_1070.awsui_animating_hyvsj_lgv7x_752:not(#\9) {
    animation: awsui_showButtons_hyvsj_lgv7x_1 var(--motion-duration-refresh-only-fast-4jn2d7, 115ms);
  }
}
@media (min-width: 689px) and (prefers-reduced-motion: reduce) {
  .awsui_show-tools_hyvsj_lgv7x_1092.awsui_has-tools-form_hyvsj_lgv7x_1070.awsui_animating_hyvsj_lgv7x_752:not(#\9) {
    animation: none;
    transition: none;
  }
}
@media (min-width: 689px) {
  .awsui-motion-disabled .awsui_show-tools_hyvsj_lgv7x_1092.awsui_has-tools-form_hyvsj_lgv7x_1070.awsui_animating_hyvsj_lgv7x_752:not(#\9), .awsui-mode-entering .awsui_show-tools_hyvsj_lgv7x_1092.awsui_has-tools-form_hyvsj_lgv7x_1070.awsui_animating_hyvsj_lgv7x_752:not(#\9) {
    animation: none;
    transition: none;
  }
  .awsui_show-tools_hyvsj_lgv7x_1092.awsui_has-tools-form-persistence_hyvsj_lgv7x_1070:not(#\9) {
    background-color: var(--color-background-container-content-z79u4u, #ffffff);
    z-index: 1;
  }
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_trigger-button-styles_hyvsj_lgv7x_1172:not(#\9) {
  background: var(--color-background-layout-toggle-default-kci9ri, #424650);
  border-start-start-radius: 50%;
  border-start-end-radius: 50%;
  border-end-start-radius: 50%;
  border-end-end-radius: 50%;
  block-size: var(--space-layout-toggle-diameter-hb85rk, 36px);
  inline-size: var(--space-layout-toggle-diameter-hb85rk, 36px);
}
.awsui_trigger-button-styles_hyvsj_lgv7x_1172:not(#\9):hover {
  background: var(--color-background-layout-toggle-hover-72w0bh, #656871);
}
.awsui_trigger-button-styles_hyvsj_lgv7x_1172:not(#\9):active {
  background: var(--color-background-layout-toggle-active-5x3zwa, #424650);
}

.awsui_trigger-badge-wrapper_hyvsj_lgv7x_1188:not(#\9) {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: path("M34.2193 10.1845C33.3961 10.579 32.4739 10.8 31.5 10.8C28.0206 10.8 25.2 7.97939 25.2 4.5C25.2 3.52614 25.421 2.6039 25.8155 1.78066C23.4518 0.639587 20.8006 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 15.1994 35.3604 12.5482 34.2193 10.1845Z");
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_trigger-badge-wrapper_hyvsj_lgv7x_1188:not(#\9):dir(rtl) {
  clip-path: path("M1.78066 10.1845C2.6039 10.579 3.52615 10.8 4.5 10.8C7.97939 10.8 10.8 7.97939 10.8 4.5C10.8 3.52614 10.579 2.6039 10.1845 1.78066C12.5482 0.639587 15.1994 0 18 0C27.9411 0 36 8.05887 36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 15.1994 0.639587 12.5482 1.78066 10.1845Z");
}

/*
Warning! If these design tokens for width change it will adversely impact
the calculation used to determine the Split Panel maximum width in the
handleSplitPanelMaxWidth function in the context.
*/
.awsui_trigger_hyvsj_lgv7x_1172:not(#\9) {
  border-block: none;
  border-inline: none;
  padding-inline: 0;
  color: var(--color-text-layout-toggle-zybz0r, #ffffff);
  cursor: pointer;
  pointer-events: auto;
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_trigger_hyvsj_lgv7x_1172:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_trigger_hyvsj_lgv7x_1172:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(3px - 1px);
}
body[data-awsui-focus-visible=true] .awsui_trigger_hyvsj_lgv7x_1172:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * 3px);
  inset-block-start: calc(-1 * 3px);
  inline-size: calc(100% + 3px + 3px);
  block-size: calc(100% + 3px + 3px);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_trigger_hyvsj_lgv7x_1172:not(#\9):focus {
  outline: none;
}
.awsui_trigger_hyvsj_lgv7x_1172.awsui_selected_hyvsj_lgv7x_1240:not(#\9) {
  background: var(--color-background-layout-toggle-selected-default-27rnk6, #006ce0);
  color: var(--color-text-layout-toggle-selected-kb3p9r, #ffffff);
}
.awsui_trigger_hyvsj_lgv7x_1172.awsui_selected_hyvsj_lgv7x_1240:not(#\9):hover {
  background: var(--color-background-layout-toggle-selected-hover-eh8bga, #004a9e);
}
.awsui_trigger_hyvsj_lgv7x_1172.awsui_selected_hyvsj_lgv7x_1240:not(#\9):active {
  background: var(--color-background-layout-toggle-selected-active-tv8i8b, #006ce0);
}
.awsui_trigger_hyvsj_lgv7x_1172.awsui_selected_hyvsj_lgv7x_1240 > .awsui_trigger-badge-wrapper_hyvsj_lgv7x_1188:not(#\9) {
  background: var(--color-background-layout-toggle-selected-default-27rnk6, #006ce0);
  color: var(--color-text-layout-toggle-selected-kb3p9r, #ffffff);
}
.awsui_trigger_hyvsj_lgv7x_1172.awsui_selected_hyvsj_lgv7x_1240 > .awsui_trigger-badge-wrapper_hyvsj_lgv7x_1188:not(#\9):hover {
  background: var(--color-background-layout-toggle-selected-hover-eh8bga, #004a9e);
}
.awsui_trigger_hyvsj_lgv7x_1172.awsui_selected_hyvsj_lgv7x_1240 > .awsui_trigger-badge-wrapper_hyvsj_lgv7x_1188:not(#\9):active {
  background: var(--color-background-layout-toggle-selected-active-tv8i8b, #006ce0);
}
.awsui_trigger_hyvsj_lgv7x_1172.awsui_badge_hyvsj_lgv7x_1260:not(#\9), .awsui_trigger_hyvsj_lgv7x_1172.awsui_badge_hyvsj_lgv7x_1260:not(#\9):hover, .awsui_trigger_hyvsj_lgv7x_1172.awsui_badge_hyvsj_lgv7x_1260:not(#\9):active {
  background: transparent;
}

.awsui_trigger-wrapper_hyvsj_lgv7x_1264:not(#\9) {
  position: relative;
  border-start-start-radius: 50%;
  border-start-end-radius: 50%;
  border-end-start-radius: 50%;
  border-end-end-radius: 50%;
}
.awsui_trigger-wrapper_hyvsj_lgv7x_1264:not(#\9):not(.awsui_remove-high-contrast-header_hyvsj_lgv7x_670) {
  box-shadow: var(--shadow-panel-toggle-0l64f2, 0px 6px 12px 1px rgba(0, 7, 22, 0.12));
}

.awsui_trigger-wrapper-tooltip-visible_hyvsj_lgv7x_1275:not(#\9) {
  /* used in test-utils*/
}

.awsui_dot_hyvsj_lgv7x_1279:not(#\9) {
  position: absolute;
  inline-size: 9px;
  block-size: 9px;
  border-start-start-radius: 8px;
  border-start-end-radius: 8px;
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
  background-color: var(--color-background-badge-icon-n0lc3o, #db0000);
  inset-block-start: 0;
  inset-inline-end: 0;
}

.awsui_trigger-tooltip_hyvsj_lgv7x_1292:not(#\9) {
  /* used in test-utils */
}